const SEO = [
	{
		page: "home",
		description:
			"I am a backend developer experienced in Node.js, Python, and AWS with a passion for building scalable, efficient, and reliable systems that power today's modern applications. Solving complex problems excites me, and I have a deep curiosity for the fundamental principles that power great backend systems. What drives me is my ability to create software solutions that drive meaningful, positive impact in our society. And by the way, did I tell you—Breaking Bad is the greatest show of all time, and Green Day is the greatest band.",
		keywords: ["Rajarshi", "Rajarshi Sengupta", "rajarshisg"],
	},

	{
		page: "experiences",
		description: "Chronological order of my professional work experience.",
		keywords: ["Rajarshi", "Rajarshi Sengupta", "rajarshisg"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of all the articles I have written so far.",
		keywords: ["Rajarshi", "Rajarshi Sengupta", "rajarshisg"],
	},

	{
		page: "projects",
		description:
			"I’m passionate about diving deep into technical concepts. While I’ve primarily been a Node.js developer, you’ll find some exciting Node.js projects I’ve worked on. Lately, I’ve been exploring systems like databases at a deeper level and have started learning Go. Most recently, I’ve been challenging myself to recreate a small in-memory database similar to Redis, all in Go.",
		keywords: ["Rajarshi", "Rajarshi Sengupta", "rajarshisg"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Rajarshi", "Rajarshi Sengupta", "rajarshisg"],
	},
];

export default SEO;
