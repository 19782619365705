const INFO = {
	main: {
		title: "Rajarshi Sengupta",
		name: "Rajarshi Sengupta",
		email: "rajarshisengupta50@gmail.com",
		logo: "../logo.png",
		resume: "https://drive.google.com/file/d/1c5JwDUi9B9JuJctVx0Zpm5msmX7BI6S8/view?usp=sharing",
	},

	socials: {
		github: "https://github.com/rajarshisg",
		linkedin: "https://www.linkedin.com/in/rajarshisg/",
		medium: "https://rajarshisg.medium.com/",
		twitter: "https://x.com/_rajarshisg",
	},

	homepage: {
		title: "Hey, I'm Rajarshi",
		description:
			"I'm a backend developer with expertise in Node.js, Python, and AWS, passionate about designing scalable, efficient, and reliable systems that power modern applications. Solving complex problems fuels my curiosity, and I'm deeply fascinated by the principles that underpin great backend architecture. My goal is to build software that delivers meaningful, positive impact in our world. Oh, and by the way—Breaking Bad is the greatest show ever made, and Green Day will always be the ultimate band.",
	},

	experiences: {
		title: "I adapt to my organization's needs and consistently drive impactful, measurable, and tangible results.",
		description: "Chronological order of my professional work experience.",
		keywords: ["Rajarshi", "Rajarshi Sengupta", "rajarshisg"],
	},

	articles: {
		title: "I enjoy exploring fascinating technical challenges from my experiences and interests, and share them when they offer valuable insights.",
		description:
			"Chronological collection of all the articles I have written so far.",
	},

	projects: {
		title: "Things I’ve made trying to become a better engineer.",
		description:
			"I’m passionate about diving deep into technical concepts. While I’ve primarily been a Node.js developer, you’ll find some exciting Node.js projects I’ve worked on. Lately, I’ve been exploring systems like databases at a deeper level and have started learning Go. Most recently, I’ve been challenging myself to recreate a small in-memory database similar to Redis, all in Go.",
	},
};

export default INFO;
