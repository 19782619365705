import React from "react";

import "./styles/experience.css";

const Experience = (props) => {
	const { company, designation, description, date, logo } = props;

	return (
		<React.Fragment>
			<div className="homepage-experience">
				<img src={logo} alt={company} className="experience-image" />
				<div className="homepage-experience-content">
					<div className="homepage-experience-title">
						<div>
							{company}, {designation}{" "}
						</div>
						<div className="homepage-experience-date">{date}</div>
					</div>
					<div className="homepage-experience-description">
						{description}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Experience;
