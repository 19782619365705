import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import INFO from "../data/user";
import SEO from "../data/seo";
import myExperiences from "../data/experiences";

import "./styles/experience.css";
import Experience from "../components/experience/experience";

const Experiences = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "experiences");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Experiences | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="experiences" />
				<div className="content-wrapper">
					<div className="experience-logo-container">
						<div className="experience-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="experience-main-container">
						<div className="title experience-title">
							{INFO.experiences.title}
						</div>

						<div className="subtitle experience-subtitle">
							{INFO.experiences.description}
						</div>

						<div className="experience-container">
							<div className="experience-wrapper">
								{myExperiences.map((experience, index) => (
									<div
										className="experience-article"
										key={(index + 1).toString()}
									>
										<Experience
											key={(index + 1).toString()}
											date={experience.date}
											company={experience.company}
											designation={experience.designation}
											logo={experience.logo}
											description={experience.description}
											link={"/article/" + (index + 1)}
										/>
									</div>
								))}
							</div>
						</div>
						<div className="page-footer">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Experiences;
