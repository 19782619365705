const myProjects = [
	{
		title: "MemoDB",
		description:
			"MemoDB is a toy key-value database. It's essentially an implementation of the popular in-memory data store, Redis, written in Go.",
		logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/go/go.png",
		linkText: "View Project",
		link: "https://github.com/rajarshisg/memodb",
	},

	{
		title: "Video Conferencing Platform",
		description:
			"A video calling software similar to Google Meet that utilizes WebRTC for real-time audio and video streaming, and WebSockets for chats.",
		logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		linkText: "View Project",
		link: "https://github.com/rajarshisg/Google-Meet-Clone",
	},

	{
		title: "Social Media Website",
		description:
			"A social media platform built with Node.js and Express.js, offering features like posts, likes, comments, friendships, chats, and more.",
		logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		linkText: "View Project",
		link: "https://github.com/rajarshisg/Social-Media-Site",
	},
];

export default myProjects;
