const myExperiences = [
	{
		date: "July 2023 - Present",
		logo: "./bp.png",
		company: "bp",
		designation: "Software Engineer",
		description:
			"At BP, I work as a backend engineer on one of the company's core IoT platforms, empowering several of our B2B customers with reliable and efficient solutions. My role involves scaling high-throughput data ingestion pipelines using Python, AWS Kinesis, and serverless technologies. I optimize data retrieval from our centralized IoT data lake powered by AWS IoT SiteWise and maintain the Backend-for-Frontend (BFF) microservice, leveraging TypeScript, Node.js, and DynamoDB for seamless performance. Occasionally, I step into front-end development with React.js, ensuring end-to-end system harmony when needed.",
		keywords: ["bp", "Rajarshi", "Rajarshi Sengupta", "rajarshisg"],
	},
	{
		date: "Nov 2021 - May 2023",
		logo: "./zluri.png",
		company: "Zluri",
		designation: "Software Engineer Intern",
		description:
			"My time at Zluri was one of the most rewarding experiences of my career, packed with invaluable learnings. As a backend developer, I worked on two core microservices for Zluri's SaaS Management Platform, managing gigabytes of daily customer data syncs and automating onboarding/offboarding workflows. I built APIs, CRON scripts, and third-party integrations using Node.js, TypeScript, and MongoDB. A highlight of my role was being a core developer for initial development of the workflows microservice, where I created several core APIs and became the SME. In my last six months, I was also involved in conducting technical interviews and mentoring new interns, adding leadership to my technical contributions.",
		keywords: ["Zluri", "Rajarshi", "Rajarshi Sengupta", "rajarshisg"],
	},
];

export default myExperiences;
