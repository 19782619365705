const myArticles = [
	{
		date: "Nov 10, 2024",
		title: "A Hands-On Attempt at Building an Event Loop in Go",
		description:
			"In this article, we explore event loops, examining how they work, their role in handling asynchronous tasks and implementing one.",
		keywords: [
			"Event Loop in Go",
			"Rajarshi",
			"Rajarshi Sengupta",
			"rajarshisg",
		],
		link: "https://medium.com/@rajarshisg/building-an-event-loop-in-go-a-hands-on-guide-ddf9344dc5be",
	},
	{
		date: "Aug 7, 2024",
		title: "Building IoT Systems: A Comprehensive Guide for Software Engineers",
		description:
			"A comprehensive guide for software engineers diving into the fascinating field of IoT.",
		style: ``,
		keywords: [
			"Building IoT Systems",
			"Rajarshi",
			"Rajarshi Sengupta",
			"rajarshisg",
		],
		link: "https://medium.com/@rajarshisg/building-iot-systems-a-comprehensive-guide-for-software-engineers-f7f967fc9918",
	},
];

export default myArticles;
